import './App.css';
import React, { useEffect} from 'react';
import Urinals from './components/urinal/Urinals';
//import UrinalContext from './components/context/urinal/urinalContext';
import UrinalState from './components/context/urinal/UrinalState';
import LayoutState from './components/context/layout/LayoutState';
import Header from './components/layout/Header'

import Share from './components/layout/Share';
import Donate from './components/layout/Donate';
import M from 'materialize-css/dist/js/materialize.min.js';
import '@fortawesome/fontawesome-free/js/all.js';
import CryptoDonateModal from './components/layout/CryptoDonateModal'
import Options from './components/urinal/Options';
import Footer from './components/layout/Footer'

import 'materialize-css/dist/css/materialize.min.css';

function App() {

  //const urinalContext = useContext(UrinalContext);

  useEffect(()=>{
    M.AutoInit();
  }, []);

  return (
    <LayoutState>
    <UrinalState>
      <Header/>
      
      <Options/>
      <div className="divider"></div>
      <Urinals/>

      <Share/>
      <Donate/>
      <CryptoDonateModal/>
      <Footer/>
    </UrinalState>
    </LayoutState>
  );
}

export default App;
