import React, {useContext} from 'react';
import UrinalContext from '../context/urinal/urinalContext';

const Urinal = (props) => {

    const urinalContext = useContext(UrinalContext);
    const { flipOccupation } = urinalContext

    const { urinal } = props;
    const { id, status, usable } = urinal;

    const urinalClick = () => {
        flipOccupation(id);
    }

    return (
        <button onClick={urinalClick} className={'urinal ' + (status === "occupied" ? "occupied" : (usable ? ' good' : ' bad'))}>
        </button>
    )
}

export default Urinal;
