import React from 'react'
import 'materialize-css/dist/css/materialize.min.css';
import { FacebookShareButton, FacebookIcon, 
         RedditShareButton, RedditIcon, 
         TwitterShareButton, TwitterIcon,
         FacebookMessengerShareButton, FacebookMessengerIcon} from 'react-share';


const Share = () => {


    let share_url = window.location.href
    let iconSize = 40;

    return (
      <div className="fixed-action-btn">
        <a href='#share' className="btn-floating blue btn-large">
            <i className="large material-icons">share</i>
        </a>
        <ul>
            <li>
              <a href='#share' className="btn-floating blue">
                <TwitterShareButton url={share_url} title={"Share to Twitter"}>
                  <TwitterIcon round size={iconSize} />
                </TwitterShareButton>
              </a>
            </li>
            <li>
              <a href='#share' className="btn-floating red">
                <RedditShareButton url={share_url} title="Share to Reddit">
                  <RedditIcon round size={iconSize} />
                </RedditShareButton>
              </a>
            </li>
            <li>
              <a href='#share' className="btn-floating blue">
                <FacebookMessengerShareButton url={share_url} title="Share to Facebook Messenger">
                  <FacebookMessengerIcon round size={iconSize} />
                </FacebookMessengerShareButton>
              </a>
            </li>
            <li>
              <a href='#share' className="btn-floating blue">
                <FacebookShareButton url={share_url} title="Share to Facebook">
                  <FacebookIcon round size={iconSize} />
                </FacebookShareButton>
              </a>
            </li> 
        </ul>
      </div>
    )
}

export default Share;
