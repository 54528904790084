import React, {useEffect} from 'react'

const Donate = () => {

    useEffect(()=>{

    }, [])

    return (
      <div className="fixed-action-btn" style={{marginRight: '70px'}}>
        <a href='#donate' className="btn-floating btn-large blue">
            <i className="large material-icons">volunteer_activism</i>
        </a>
        <ul>
            <li><a href="#crypto-modal" className="btn-floating red modal-trigger">
                <i className="fab fa-btc"></i>
                {/* <i className="material-icons"></i> */}
            </a></li>
            <li><a target="_blank" href="https://www.paypal.com/donate?hosted_button_id=D3YABRLQ3W5KQ" className="btn-floating blue">
                <i className="material-icons">paid</i>
            </a></li>
        </ul>
      </div>
    )
}

export default Donate;
