import {
    LOAD_URINALS,
    UPDATE_SYMMETRY,
    UPDATE_AGGRESSIVE,
    UPDATE_ACCEPTABLE,
    UPDATE_MIN_GAP
} from '../types'

const UrinalReducer = (state, action) => {
    switch(action.type){
        case LOAD_URINALS:
            return {
                ...state,
                urinals: action.payload
            }
        case UPDATE_SYMMETRY:
            return {
                ...state,
                check_symmetry: action.payload
            }
        case UPDATE_AGGRESSIVE:
            return {
                ...state,
                aggressive: action.payload
            }
        case UPDATE_ACCEPTABLE:
            return {
                ...state,
                acceptable_gap: action.payload
            }
        case UPDATE_MIN_GAP:
            return {
                ...state,
                min_gap: action.payload
            }
        default: 
            return state;
    }
}

export default UrinalReducer;