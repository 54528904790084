import React, { useContext } from 'react'
import UrinalContext from '../context/urinal/urinalContext';
import LayoutContext from '../context/layout/layoutContext';
import Spinner from '../layout/Spinner';

const Options = () => {

    const layoutContext = useContext(LayoutContext);
    const { show_settings } = layoutContext;

    const urinalContext = useContext(UrinalContext);
    const { updateSymmetry, updateMinGap, updateAcceptableGap, min_gap, acceptable_gap, max_urinals} = urinalContext; //updateAggressive

    const setSymmetry = (e) => {
        updateSymmetry(e.target.checked);
    }

    // const setAggressive = (e) => {
    //     updateAggressive(e.target.checked);
    // }

    const setMinGap = (value) => {
        updateMinGap(value);
    }

    const setAcceptableGap = (value) => {
        updateAcceptableGap(value);
    }

    return (
        show_settings ? (
        <div className="options">

            <div className="option-group">
                <label>Always-acceptable gap</label>
                <Spinner increment={1} initialValue={acceptable_gap} min={min_gap} max={max_urinals} onChange={setAcceptableGap}/>
            </div>
            <div className="option-group">
                <label>Minimum gap</label>
                <Spinner increment={1} initialValue={min_gap} min={0} max={acceptable_gap} onChange={setMinGap}/>
            </div>
            {/* <div className="switch">
                <label>
                    Territorial
                    <input type="checkbox" onClick={setAggressive}/>
                    <span className="lever"></span>
                    Aggressive
                </label>
            </div> */}
            
            {/* <div className="option-group">
                <label htmlFor="min">Min</label>
                <input name="min" type='number' min='0' max={max_urinals} step='1' value={min_gap} onChange={setMinGap}/>
            </div>
            
            <div className="option-group">
                <label htmlFor="acceptable">Acceptable</label>
                <input name="acceptable" type='number' min='0' max={max_urinals} step='1' value={acceptable_gap} onChange={setAcceptableGap}/>
            </div> */}

            <div className="option-group">
                <label htmlFor="symmetry-switch">Symmetry</label>
                <div className="switch" >
                    <label>
                        <input name="symmetry-switch" type="checkbox" onClick={setSymmetry}/>
                        <span className="lever"></span>
                    </label>
                </div>
            </div>
            
        </div>

        ) : (
            <div></div>
        )

    )
}


export default Options