import React from 'react'
//import 'materialize-css/dist/css/materialize.min.css';
import CryptoDonate from 'dot-crypto-donate-react';

const CryptoDonateModal = () => {

    return (
      <div id="crypto-modal" className="modal">
        <div className="modal-content">
          <h4>Support content like this with a crypto donation</h4>
          <p>Bitcoin and Ethereum accepted</p>
          <CryptoDonate
                cryptoDomain="dallinromney.crypto"
                // infuraApi="<insert-infura-api>"
                colors={{
                    primary: "#2096f3",
                    secondary: "#fde199",
                    button: "#1a78c2",
                    buttonSecondary: "#fab601",
                    text: "#ffffff",
                }}
                text={{
                    title: "Donate",
                    thanks: "Thank you for donating, see the below link for transaction",
                    copied: "Address copied, please donate via wallet",
                }}
                />
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close waves-effect waves-green btn-flat">Done</a>
        </div>
      </div>
    )
}

export default CryptoDonateModal;
