import React, {useContext} from 'react';
import LayoutContext from '../context/layout/layoutContext';

const Header = () => {

    const layoutContext = useContext(LayoutContext);
    const { toggleSettings } = layoutContext;

    return (

    // <div className="navbar-fixed lime accent-2">
    //     <nav>
    //         <a href="#settings" onClick={toggleSettings}>
    //             <i className="material-icons black-text sidenav-trigger">settings</i>
    //         </a>
    //         <div className="nav-wrapper lime accent-2">
    //             <a href="#!" className="black-text brand-logo">Where Can I Pee?</a>
    //         </div>
    //     </nav>
    // </div>

    <header className="nav lime accent-2">
        
        {/* <a href="#settings" > */}
        <i onClick={toggleSettings} className="material-icons black-text settings-button">settings</i>
        {/* </a> */}
        <h1 className="black-text">Where Can I Pee?</h1>
        <h5>read: the primal male mind</h5>
    </header>

    )
}

export default Header;

