import {
    TOGGLE_SETTINGS
} from '../types'

const LayoutReducer = (state, action) => {
    switch(action.type){
        case TOGGLE_SETTINGS:
            return {
                ...state,
                show_settings: !state.show_settings
            }
        default: 
            return state;
    }
}

export default LayoutReducer;