import React, {useReducer} from 'react'
import LayoutContext from './layoutContext';
import LayoutReducer from './layoutReducer';

import {
    TOGGLE_SETTINGS
} from '../types';

// check environment
if(process.env.NODE_ENV !== 'production'){
    
} else {
    
}

const LayoutState = props => {
    const initialState = {
        show_settings: false
    }

    const [state, dispatch] = useReducer(LayoutReducer, initialState);

    let { show_settings } = state;

    // add a urinal to the end
    const toggleSettings = () => {
        dispatch({type: TOGGLE_SETTINGS});
    }

    // return provider
    return <LayoutContext.Provider
        value={{
            toggleSettings,
            show_settings
        }} >
            {props.children}
    </LayoutContext.Provider>
}

export default LayoutState;

