import React from 'react'

const Footer = () => {
    return (
        <footer>
            <p>Click a urinal to toggle occupation</p>
            <p>Copyright 2021 &copy; Dallin Romney</p>
        </footer>
    )
}

export default Footer
