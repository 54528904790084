// URINAL
export const LOAD_URINALS = "LOAD_URINALS";
export const ADD_URINAL = "ADD_URINAL";
export const REMOVE_URINAL = "REMOVE_URINAL";
export const UPDATE_SYMMETRY = "UPDATE_SYMMETRY";
export const UPDATE_AGGRESSIVE = "UPDATE_AGGRESSIVE";
export const UPDATE_ACCEPTABLE = "UPDATE_ACCEPTABLE";
export const UPDATE_MIN_GAP = "UPDATE_MIN_GAP";


// LAYOUT
export const TOGGLE_SETTINGS = "TOGGLE_SETTINGS";