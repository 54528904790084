import React, {useReducer} from 'react'
import UrinalContext from './urinalContext';
import UrinalReducer from './urinalReducer';
import {
    LOAD_URINALS,
    UPDATE_SYMMETRY,
    UPDATE_AGGRESSIVE,
    UPDATE_MIN_GAP,
    UPDATE_ACCEPTABLE
} from '../types';

// check environment
if(process.env.NODE_ENV !== 'production'){
    
} else {
    
}

const UrinalState = props => {
    const initialState = {
        urinals: [
            {
                id: 0,
                status: "empty",
                usable: true
            },
            {
                id: 1,
                status: "empty",
                usable: true
            },
            {
                id: 2,
                status: "empty",
                usable: true
            },
            {
                id: 3,
                status: "empty",
                usable: true
            },
            {
                id: 4,
                status: "empty",
                usable: true
            }
        ],
        max_urinals: 10,
        min_gap: 0,
        acceptable_gap: 3,
        aggressive: false,
        check_symmetry: false
    }

    const [state, dispatch] = useReducer(UrinalReducer, initialState);

    let { urinals, max_urinals, aggressive, check_symmetry, min_gap, acceptable_gap } = state;

    // add a urinal to the end
    const addUrinal = () => {
        if(urinals.length < max_urinals) {
            urinals = [...urinals, {id: urinals.length, status: "empty", usable: true}]
            dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals)})
        }
    }

    // Evaluate urinals!
    const whereCanIPee = (urinals) => {
        
        const num_urinals = urinals.length;
    
        // CREATE ARRAY OF 1s and 0s REPRESENTING URINAL OCCUPATION; 0 = occupied, 1 = available
        let urinals_in = [];
        urinals.forEach((urinal) => {
            if(urinal.status === "occupied") urinals_in.push(1);
            else urinals_in.push(0);
        });

            // console.log(urinals_in)

        // For each urinal, find the smallest gap to the left or right to the next occupied urinal
        let minimum_distance = [];
        let right_distance, left_distance, left, right;
        for(let urinal_index = 0; urinal_index < num_urinals; urinal_index++){
            // check to the left
            left_distance = Infinity;
            if(urinal_index !== 0){
                for (left = urinal_index; left >= 0; left--){
                    left_distance = urinal_index - left;
                    if(urinals_in[left - 1] === 1) break;
                    if(left === 0) left_distance = Infinity;
                }
            }

            // check to the right
            right_distance = Infinity;
            if(urinal_index !== num_urinals - 1){
                for (right = urinal_index; right < num_urinals; right++){
                    right_distance = right - urinal_index;
                    if(urinals_in[right + 1] === 1) break;
                    if(right === num_urinals -1) right_distance = Infinity;
                }
            }
            
            // push smallest distance
            minimum_distance.push(right_distance < left_distance ? right_distance : left_distance);
        }
            // console.log("closest occupied urinal: " + minimum_distance);


        // create array with ones where min gap is met or exceeded
        let usable_min = [];
        minimum_distance.forEach((distance) => {
            if(distance >= min_gap) usable_min.push(1);
            else usable_min.push(0);
        })
            //console.log("min gap of " + min_gap + " met: " + usable_min)

        // create array with ones where gap is greater than or equal to acceptable gap
        let usable_acceptable = [];
        minimum_distance.forEach((distance) => {
            if(distance >= acceptable_gap) usable_acceptable.push(1);
            else usable_acceptable.push(0);
        })
            //console.log("acceptable gap of " + acceptable_gap + " met: " + usable_acceptable)
            

        // Find urinals with furthest distance!
        let farthest_distance = -1;
        let best_spaced_indexes = []
        let current_distance;
        for(let urinal_index = 0; urinal_index < num_urinals; urinal_index++){
            // if it's not occupied
            if (urinals_in[urinal_index] === 0){
                current_distance = minimum_distance[urinal_index];
                // if it's the longest gap discovered so far, update longest gap and start over
                if(current_distance > farthest_distance){
                    best_spaced_indexes = [urinal_index];
                    farthest_distance = current_distance;
                } else if(current_distance === farthest_distance){
                    best_spaced_indexes.push(urinal_index);
                }
            }
        }
    
            //console.log("best urinal positions: " + best_spaced_indexes)

        // Create array with ones at best indexes based on spacing
        let usable_territorial = new Array(num_urinals).fill(0);
        best_spaced_indexes.forEach((index) => {
            usable_territorial[index] = 1;
        })

    // SYMMETRY
            //console.log('symmetry: ' + check_symmetry)
        let usable_symmetry = new Array(num_urinals).fill(0);

        if (check_symmetry){
            for(let urinal_index = 0; urinal_index < num_urinals; urinal_index++){
                // if urinal is not occupied
                if(urinals_in[urinal_index] === 0){
                    // Make a copy of urinals and set current index to occupied
                    let urinals_in_copy = [...urinals_in];
                    urinals_in_copy[urinal_index] = 1;

                    // Reverse the copy and check if it would be identical reversed
                    let urinals_in_copy_reversed = [...urinals_in_copy];
                    urinals_in_copy_reversed.reverse();
                
                        // console.log(urinals_in_copy);
                        // console.log(urinals_in_copy_reversed);
                    if(arraysEqual(urinals_in_copy_reversed, urinals_in_copy)){
                        // If it would be identical, then allow use of that urinal!
                        // console.log("MATCH!");
                        usable_symmetry[urinal_index] = 1;
                    }
                }
            }
        }
        //console.log(usable_symmetry);
    
        // Find all gaps of the longest gap length, and where they start
            

    
        // Merge Results
        // It can either meet acceptable gap OR (meet min gap AND (be a best index OR create symmetry if symmetry setting set))
        let urinals_out = arrayOR(usable_acceptable, arrayAND(usable_min, arrayOR(usable_territorial, usable_symmetry)));

        // set urinal usable properties based on results
        urinals.forEach((urinal) => {
            if(urinals_out[urinal.id] === 1) urinal.usable = true
            else urinal.usable = false;
        });

        return urinals;

    }

    const updateSymmetry = (symmetry_bool) => {
        dispatch({type: UPDATE_SYMMETRY, payload: symmetry_bool});
        check_symmetry = symmetry_bool;
        dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals)})
    }

    const updateAggressive = (aggressive_bool) => {
        dispatch({type: UPDATE_AGGRESSIVE, payload: aggressive_bool})
        aggressive = aggressive_bool;
        dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals)})
    }

    const updateMinGap = (new_min_gap) => {
        //if(new_min_gap <= acceptable_gap){
            dispatch({type: UPDATE_MIN_GAP, payload: new_min_gap})
            min_gap = new_min_gap;
            dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals)})
        //}
    }

    const updateAcceptableGap = (new_acceptable_gap) => {
        //if(new_acceptable_gap >= min_gap){
            dispatch({type: UPDATE_ACCEPTABLE, payload: new_acceptable_gap})
            acceptable_gap = new_acceptable_gap;
            dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals)})
        //}
    }

    // Flip urinal occupation
    const flipOccupation = (id) => {
        if (urinals[id].status !== "occupied"){
            urinals[id].status = "occupied"
        } else {
            urinals[id].status = "empty"
        }
        
        dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals)})
    }

    const removeUrinal = () => {
        if(urinals.length > 1) {
            dispatch({type: LOAD_URINALS, payload: whereCanIPee(urinals.slice(0, -1))})
        }
    }

    // return provider
    return <UrinalContext.Provider
        value={{
            urinals,
            max_urinals,
            min_gap,
            acceptable_gap,
            aggressive,
            check_symmetry,
            addUrinal,
            removeUrinal,
            flipOccupation,
            updateSymmetry,
            updateAggressive,
            updateMinGap,
            updateAcceptableGap
        }} >
            {props.children}
    </UrinalContext.Provider>
}

export default UrinalState;



// FUNCTIONS FOR COMPARING ARRAYS
function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}

function arrayAND(a, b) {
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    let and_array = new Array(a.length)
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== 0 && b[i] !== 0){
        and_array[i] = 1;
      } else {
        and_array[i] = 0;
      }
    }
    return and_array;
}

function arrayOR(a, b) {
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    let and_array = new Array(a.length)
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== 0 || b[i] !== 0){
        and_array[i] = 1;
      } else {
        and_array[i] = 0;
      }
    }
    return and_array;
}