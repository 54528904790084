import React, {useContext} from 'react'
import UrinalContext from '../context/urinal/urinalContext';
import Urinal from './Urinal';

const Urinals = () => {

    const urinalContext = useContext(UrinalContext);

    const { addUrinal, removeUrinal } = urinalContext;

    const { urinals, max_urinals } = urinalContext;

    return (
        <div className='row' style={urinalsStyle}>
            {urinals.map((urinal) => <Urinal key={urinal.id} urinal={urinal}/>)}
            <div>
                {urinals.length < max_urinals && (
                <div>
                    <button onClick={addUrinal} className="add-remove green">
                        <i className="material-icons white-text">add</i>
                    </button>
                </div>
                )}
                {urinals.length > 1 && (
                <div>
                    <button onClick={removeUrinal} className="add-remove red">
                        <i className="material-icons white-text">remove</i>
                    </button>
                </div>
                )}
            </div>
        </div>  
    )
}

export default Urinals;

const urinalsStyle = {
    display: 'flex',
    //margin: '50px 50px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    margin: 0
};