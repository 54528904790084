import React, {useRef, useState} from 'react'

const Spinner = (props) => {

    let {decreaseText, increaseText, increment, initialValue, min, max, onChange} = props;

    decreaseText = decreaseText || 'Decrease quantity';
    increaseText = increaseText || 'Increase quantity';
    increment = increment || 1;
    initialValue = initialValue || 0;
    min = min || 0;
    max = max || 10;

    const inputElement = useRef(null);

    const [value, setValue] = useState(initialValue);

    const increaseQuantity = () => {
        let newVal = Number(inputElement.current.value) + increment;
        if(newVal <= max) {
            setValue(newVal);
            inputElement.current.value = newVal;
            onChange(newVal);
        }   
    }
    const decreaseQuantity = () => {
        let newVal = Number(inputElement.current.value) - increment;
        if(newVal >= min) {
            setValue(newVal);
            inputElement.current.value = newVal;
            onChange(newVal);
        }   
    }

    const changeQuantity = (e) => {
        let val = inputElement.current.value;
        if(isNaN(val)) setValue(initialValue);
        else if(val > max) setValue (max);
        else if(val < min) setValue (min);
        else setValue(e.target.value)
    }
 
    return (
        <div styles={outerStyles}>
            <button style={decreaseStyles} onClick={decreaseQuantity} title={decreaseText} type="button">
                <i className="material-icons black-text">remove</i>
            </button>

            <input style={inputStyles} ref={inputElement} pattern='[0-9]+' name='quantity' type='number' value={value} onChange={changeQuantity} />

            <button style={increaseStyles} onClick={increaseQuantity} title={increaseText} type="button">
                <i className="material-icons black-text">add</i>
            </button>
        </div>
    );
}

export default Spinner;


const outerStyles = {
    position: 'relative',
    width: '100%',
    padding: '0',
    margin: '0',
    border: '0',
    display: 'flex'
}

const inputStyles={
    fontSize: '3vw',
    height: '4vw',
    borderRadius: '2vw',
    border: '0',
    background: '#fff',
    color: '#222',
    //boxShadow: '0 10px 65px -10px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
    width: '50%',
    boxSizing: 'border-box',
    fontWeight: 'lighter',
    display: 'inline'
}
  
const decreaseStyles = {
    position: 'relative',
    width: '4vw',
    height: '4vw',
    left: '4vw',
    // top: '-0.4vw',
    display: 'inline',
    padding: '0',
    margin: '0',
    border: '0',
    borderRadius: '2vw',
    cursor: 'pointer',
    transition: 'opacity 0.15s',
    opacity: '0.7',
    border: '1px solid'
  }

  const increaseStyles = {
    position: 'relative',
    width: '4vw',
    height: '4vw',
    left: '-4vw',
    // top: '-0.4vw',
    display: 'inline',
    padding: '0',
    margin: '0',
    border: '0',
    borderRadius: '2vw',
    cursor: 'pointer',
    transition: 'opacity 0.15s',
    opacity: '0.7',
    border: '1px solid'
  }

  //   [data-quantity] input:focus {
//     outline: none;
//     box-shadow: 0 5px 55px -10px rgba(0, 0, 0, 0.2), 0 0 4px #3fb0ff; /* Allows border radius on focus */
//   }
  
//   [data-quantity] input[type=number]::-webkit-inner-spin-button,
//   [data-quantity] input[type=number]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
  
//   [data-quantity] input[type=number] {
//     -moz-appearance: textfield;
//   }
  
  
//   [data-quantity] button:active {
//     background-position-y: 1px;
//     box-shadow: inset 0 2px 12px -4px #c5d1d9;
//   }
  
//   [data-quantity] button:focus {
//     outline: none;
//   }
  
//   [data-quantity] button:hover {
//     opacity: 1;
//   }
  
//   [data-quantity] button.sub {
//     left: 0.6rem;
//   }
  
//   [data-quantity] button.add {
//     right: 0.6rem;
//     background-position-x: -2.8rem;
//   }
  